
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { tagsOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  props: {
    hideText: {
      type: Boolean,
    },
    btnText: {
      type: String,
      default: 'Create',
    },
    modalTitle: {
      type: String,
      default: 'New list',
    },
    label: {
      type: String,
      default: 'List name',
    },
    name: {
      type: String,
    },
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const nameValue = ref(props.name)
    const tagsValue = ref(props.tags)
    const tagsList = ref(tagsOptions)

    return {
      nameValue,
      tagsValue,
      tagsList,
    }
  },
})
