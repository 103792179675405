import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "body-text-regular-14 mb-4 lh-20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "contactsLists",
    "modal-title": _ctx.modalTitle,
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      (!_ctx.hideText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " List is a static group of contacts that is organized manually by the workspace users. "))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_form_line, {
        label: _ctx.label,
        class: "mb-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.nameValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nameValue) = $event)),
            validators: "required",
            "error-hint": "List name cannot be empty",
            placeholder: "Enter list name",
            autofocus: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_tm_form_line, { label: "Tags" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.tagsValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tagsValue) = $event)),
            options: _ctx.tagsList,
            type: "tags",
            placeholder: "Enter tag name"
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      })
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        color: "primary",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title"]))
}